import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";

// images
import banner_img from "../images/icons/p27.svg";
import safe_icon from "../images/skicka_faktura_safe.png";
import scandinavia_img from "../images/scandinavia.svg";

const BOT_CODE = "l8QZLG6U";
const BOT_CODE_DEMO = "WfgujSpL";

function Betalningar_p27() {
    const [chatbot_1, setChatbot_1] = useState(false);
    const [chatbot_boka_demo_1, set_chatbot_boka_demo_1] = useState(false);
    const [chatbot_boka_demo_2, set_chatbot_boka_demo_2] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Betalningar P27 | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="P27 kompabilitet med Crediflow" />
                <meta name="keywords" content="Betalningar P27" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="P27 kompabilitet med Crediflow" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.crediflow.se/betalningar-p27" />
                <link rel="cannonical" href="https://www.crediflow.se/betalningar-p27" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">P27 kompabilitet med Crediflow</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        P27 är ett gemensamt initiativ av Danske Bank, 
                                        Handelsbanken, Nordea, OP Financial Group, SEB 
                                        och Swedbank för att etablera en nordisk 
                                        betalningsinfrastruktur för inhemska och 
                                        gränsöverskridande betalningar i de nordiska 
                                        valutorna och euro. 
                                        <br/><br/>
                                        Crediflow följer utvecklingen noga och vi hjälper 
                                        redan företag och partners att konvertera 
                                        till och stödja ISO 20022.
                                    </p>
                                    <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                                    {chatbot_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={BOT_CODE} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 sm:1/3 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="service och support optosweden" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Hem</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">betalningar-p27</span>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 pt-12" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">En nordisk satsning</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                P27 Nordic Payments Platform är en nordisk satsning 
                                där målet är att skapa en gemensam modern och 
                                framtidssäkrad infrastruktur för betalningar i Norden. 
                                Det är ett bankgemensamt projekt där syftet är att 
                                främja handeln inom och mellan de nordiska länderna 
                                bland annat genom att möjliggöra betalningar i 
                                realtid över gränserna.
                                <br/><br/>
                                Målet med P27 är effektivare betalningar i de nordiska 
                                valutorna och euron med ett system. Standardisering är 
                                ett av huvudsyftena med P27 och därför bygger P27 
                                på ISO 20022-formatet.
                            </p>
                            <div className="mt-10 flex">
                                <button onClick={() => set_chatbot_boka_demo_1(true)} className="px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Boka Demo</button>
                                <a href="https://nordicpayments.eu/" target="_blank" rel="noreferrer"className="ml-3 px-10 py-2 rounded-full text-blue-custome border border-blue-custome bg-white shadow-md hover:bg-blue-100">Läs mer</a>
                            </div>
                            
                            {chatbot_boka_demo_1 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={BOT_CODE_DEMO} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>
                
                        <div className="w-2/3 p-4 mx-auto">
                            <img className="w-full" src={scandinavia_img} alt="Skicka e fakura" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-28" id="skickaFaktura_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100 pb-32 " >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={safe_icon} alt="P27-projekt" />
                        </div>
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">Tidplan</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                P27-projektet kommer att fortgå under flera år och pågå 
                                under flera etapper. Den första delen av migreringen 
                                avser betalningar i svenska kronor inom Sverige och 
                                löper under 2022-2024.
                                <br/><br/>
                                För mer information och vilka 
                                bankkopplingar som redan finns, 
                                kontakta info@crediflow.se
                            </p>
                            <button onClick={() => set_chatbot_boka_demo_2(true)} className="px-7 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                            {chatbot_boka_demo_2 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={BOT_CODE} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Betalningar_p27;